import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'gifts',
  formId: 'UK%20general%20gifts',
  title: 'Gaver',
  subTitle: 'Sig det. Med gaver.',
  icon: '/icons/shopping.svg',
  image: '/assets/images/sunrise/gifts_supermercat.png',
  theme: 'sunrise-gifts',
  sections: [
    {
      sectionId: 'hero',
      label: 'Gaver',
      title: 'Sig det. Med gaver.',
      description:
        'Taknemmelighed, der ikke bliver udtrykt, er som en gave der bliver pakket fint ind men ikke givet. Et skulderklap og et smil virker også, men intet bliver hængende i bevidstheden som en dejlig gave, der igen og igen giver den der varme følelse i maven.',
      image: '/assets/images/sunrise/gifts_supermercat.png',
      icon: '/icons/gifts.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Gaver i alle farver og størrelser!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/gifts/service_1.svg',
          title: 'Mærkedage',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_2.svg',
          title: 'Store og små fødselsdage',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_3.svg',
          title: 'Jul',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_4.svg',
          title: 'Værdsættelse',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Vejen til perfekte gaver!',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/gifts/journey_1.svg',
          title: 'Del dit behov',
          description: 'Vi har et kæmpe udvalg og helt sikkert det, der lige passer til din begivenhed.',
        },
        {
          icon: '/assets/images/services/gifts/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Vi laver et detaljeret tilbud. Det er kun gaverne vi pakker flot ind.',
        },
        {
          icon: '/assets/images/services/gifts/journey_3.svg',
          title: 'Ja tak!',
          description: 'Det var det! Du har forhåbentlig en dejlig julemandsfølelse i maven?',
        },
        {
          icon: '/assets/images/services/gifts/journey_4.svg',
          title: 'Forkæl nogen',
          description: 'Og bliv selv forkælet med deres overraskelse og glæde',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor vore kunder elsker at give gaver fra Good Monday',
      description: '',
      image: '/assets/images/services/gifts/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'Vi har gjort det længe og har en idebank på størrelse med Rundetårn.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'Mindre bøvl',
          description:
            'Der går hurtigt nogle timer med at udtænke, og siden tilvejebringe, den perfekte gave, og de timer kan du bruge på noget andet - og stadig være den glade giver.',
        },
        {
          icon: 'eva eva-gift-outline',
          title: 'Fra idé til gave',
          description: 'Vi fører dine gode intentioner ud i livet.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at Good Monday kan tage sig af alle dine julegaver, inklusive indpakning og kort?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
